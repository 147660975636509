import React from 'react';

import './styles.css';

export const Spinner: React.FC = () => (
    <div className='spinner' role='progressbar'>
        <div />
        <div />
        <div />
        <div />
    </div>
);
